import { createStore } from 'vuex'
import ConfigMixing from '../mixings/ConfigMixing';



    const navigationModule = {
    state: () => ({ 
        pageTitle     : '',
        pageIcon      : '',
        tempProduct    : null,
        tempRelationship: null,
        tempPurchaseOrder: null,
        tempAdjustment: null,
        tempStockTransfer: null,
        tempRegisterSession:null,
        tempSalesOrder : null
    }),
        mutations: {
        setTempRegisterSession(state, tempRegisterSession){
            state.tempRegisterSession = tempRegisterSession;
        },
        setTempStockTransfer(state, tempStockTransfer){
            state.tempStockTransfer = tempStockTransfer;
        },
        setTempAdjustment(state, tempAdjustment){
            state.tempAdjustment = tempAdjustment;
        },
        setTempRelationship(state, tempRelationship){
            state.tempRelationship = tempRelationship;
        },
        setTempSalesOrder(state, tempSalesOrder){
            state.tempSalesOrder = tempSalesOrder;
        },
        setTempPurchaseOrder(state, tempPurchaseOrder){
            state.tempPurchaseOrder = tempPurchaseOrder;
        },
        setTempProduct(state, tempProduct){
            state.tempProduct = tempProduct;
        },
        setPageIcon(state, icon){
            state.pageIcon = icon;
        },
        setPageTitle(state, title){
            state.pageTitle = title;
        },
    }
    }

    const moduleConfig = {
        state: () => ({ 
            loaded: false,
            defaultCustomer : null,
            version     : null,
            build       : null,
            buildDate: null,
            headerAlert: null,
            permissions: [],
            categories : undefined,
            permissionsName:[],
            qbHrefCustomer: null,
            qbHrefBill: null,
            qbHrefVendor: null,
            qbHrefInvoice: null,
            qbHrefPayment: null,
            apiUrl      :null, 
            env: null,
            isQbOnline : false,
            locations: [],
            registers: [],
            printers: [],
            role:'',
            paymentMethods:[],
            taxTypes   : [],
            paymentTerms: {},
            unities     : [],
            licensedFor : '',
            logoUrl : '',
            logoWhite   : '',
            isProd      : '0',
        }),
        mutations: {
            setCategories(state, categories){
                state.categories = categories;
            },
            
            setUserRole(state, role){
                state.role = role;
            },
            setLoaded(state, loaded) {
                state.loaded = loaded
            },
            setPermissionsName(state, permissionsName) {
                state.permissionsName = permissionsName
            },
            setDefaultCustomer(state, defaultCustomer) {
                state.defaultCustomer = defaultCustomer
            },
            setHeaderAlert(state, alert) {
                state.headerAlert = alert
            },
            setApiUrl(state, apiUrl) {
                state.apiUrl = apiUrl
            },
            setVersion(state, version){
                state.version = version
            },
            setPrinters(state, printers){
                state.printers = printers
            },
            setRegisters(state, registers){
                state.registers = registers
            },
            setUnities(state, unities){
                state.unities = unities
            },
            setPaymentMethods(state, paymentMethods){
                state.paymentMethods = paymentMethods
            },
            setPaymentTerms(state, paymentTerms){
                state.paymentTerms = paymentTerms
            },
            setLocations(state, locations){
                state.locations = locations
            },
            setEnv(state, env){
                state.env = env;
            },
            setIsProd(state, is){
                state.isProd = is;
            },
            setLogoWhite(state, url){
                state.logoWhite = url;
            },
            setLogo(state, url){
                state.logoUrl = url;
            },
            setLicensedFor(state, name){
                state.licensedFor = name;
            },
            setIsQbOnline(state, is){
                state.isQbOnline = is;
            },
            setConfig(state, appDetails){
                state = appDetails;
            },
            setQbhrefCustomer(state, href){
                state.qbHrefCustomer = href;
            },
            setQbhrefVendor(state, href){
                state.qbHrefVendor = href;
            },
            setQbhrefInvoice(state, href){
                state.qbHrefInvoice = href;
            },
            setQbhrefPayment(state, href){
                state.qbHrefPayment = href;
            },
            
            setQbhrefBill(state, href){
                state.qbHrefBill = href;
            },
            setTaxTypes(state, taxTypes){
                state.taxTypes = taxTypes;
            }          
        }
    }

    const moduleSession = {
        state: () => ({ 
            lastRefresh     : null,
            logged          : false,
            jwt             : null,
            userName        : null,
            userId: null,
            userRole:null,
            sessionId       : null,
            refreshToken    : null,
        }),
        mutations: {
            setLogged(state, { logged }){
                state.logged = logged;
            },
            setLastRefresh(state){
                state.lastRefresh = new Date();
            },
            setLogout(state){
                state.jwt = null;
                state.logged = false;
                state.sessionId = null;
                state.refreshToken = null; 
                localStorage.removeItem('cache_config_pl');
                localStorage.removeItem('pl-login');
            },
            setLogin(state, { jwt, refreshToken, sessionId, userRole }){
                state.jwt = jwt;
                state.logged = true;
                state.userRole = userRole
                state.sessionId = sessionId;
                state.refreshToken = refreshToken;
                getUserInfo();
                persistSession();
            },

            setUserName(state, userName){
                state.userName = userName;
            },
            setUserId(state, userId){
                state.userId = userId;
            },
            setUserRole(state, userRole){
                state.userRole = userRole;
            },
            setRefreshToken(state, refreshToken){
                state.refreshToken = refreshToken;
            },
            setSessionId(state, sessionId){
                state.sessionId = sessionId;
            }
        }
    }

    const cacheSession = {
        state: () => ({ 
            assets: { 
                type: [],
                brands: [],
                
            },
        }),
        mutations: {
            setTabs(state, tabs) {
                state.tabs = tabs;
            },
        }
    }

    const tabSession = {
        state: () => ({ 
            tabs        : [],
            tabsI       : [],
            activeTab    : { },
        }),
        mutations: {
            closeTab(state, key) {
                let _it = state.tabs.find(function (d) { return d.key === key });
                let index = state.tabs.indexOf(_it);
                state.tabs.splice(index, 1);
                if (state.tabs.length >= index - 1) {
                    state.activeTab = state.tabs[index - 1].key;
                }
            },
            setTabs(state, tabs) {
                state.tabs = tabs;
            },
            setTab(state, key) {
                state.activeTab = key;
            },
            setTabIcon(state, {key, icon}) {
                for (const tab of state.tabs) {
                    if (tab.key == key) {
                        tab.icon = icon;
                    }
                }
            },
            setTabData(state, {key, data}) {
                for (const tab of state.tabs) {
                    if (tab.key == key) {
                        tab.data = data;
                    }
                }
            },
            removeClosableTabs(state) {
                var state2 = [];
                for (const tab of state.tabs) {
                    if (!tab.canClose) {
                        state2.push(tab);
                        state.activeTab = tab.key;
                    }
                }
                state.tabs = state2;
            },
            setTabLabel(state, { key, label }) {
                var tab = state.tabs.find(function (d) { return d.key == key });
                if (tab) {
                    tab.label = label;
                } 
            },    
            setTabLoading(state, { key, loading }) {
                var tab = state.tabs.find(function (d) { return d.key == key });
                if (tab) {
                    tab.loading = loading;
                } 
            },
            addTab(state, { initiate, label, key, type, canClose, icon, isDefault, active, loading = false }) {    
                if (initiate) {
                    state.tabs = [];
                }
                if (!state.tabs.find(function (d) { return d.key == key })) {
                   state.tabs.push({ label, key, type, canClose, active, icon, isDefault, loading });
                } 
                state.activeTab = key;
            }
        }
    }

    const store = createStore({
        modules: {
            session : moduleSession,
            config:  moduleConfig,
            navigation: navigationModule,
            tabs: tabSession,
            cache : cacheSession
        }
    })

    function setConfig(data) {
        var user = data.user;
        if (user) {
            store.commit('setUserName', user.userName);
            store.commit('setUserId', user.userId);
            store.commit('setUserRole', user.userRole);
        }   
        /*
            store.commit('setLicensedFor', data.system.licensedFor);
            store.commit('setLogoWhite', data.system.logoWhiteUrl);
            store.commit('setLogo', data.system.logoColorUrl);
            store.commit('setHeaderAlert', data.system.alertMessage);
            */
        store.commit('setLoaded', true);

    }

    function  loadConfig(){
        return new Promise( (resolve, reject) => {
            try {
                var cacheConfig = localStorage.getItem('cache_config_pl');
                if (!cacheConfig || JSON.parse(cacheConfig).exp <= new Date().getTime()) {
                    console.log('cache_config not exist');
                    ConfigMixing.config().then(function(c){
                        setConfig(c.data);
                        var cache = {
                            exp: new Date().getTime() + (8 * 60 * 60 * 1000),
                            data : c.data
                        }
                        var cacheConfig = JSON.stringify(cache);
                        localStorage.setItem('cache_config_pl', cacheConfig)


                        resolve(true);
                    }).catch(function(){
                        reject(false);
                    }) 
                } else {
                    console.log('cache_config exist!');
                    cacheConfig = JSON.parse(localStorage.getItem('cache_config_pl'));
                    setConfig(cacheConfig.data);
                    resolve(true);
                }


            } catch{
                reject(false);
            }
        });
    }

const getUserInfo = () => {
        localStorage.removeItem('cache_config');
        console.log(`Loading Config...`);
        loadConfig();
    

        //CategoryMixing.getList();

        ConfigMixing.config().then(function(c){
            console.log(`Config OK`);
            var user = c.data.user;
            store.commit('setUserRole', user.userRole);
            store.commit('setUserName', user.userName);
            store.commit('setUserId', user.userId);
            store.commit('setLicensedFor', c.data.system.licensedFor);
            store.commit('setLogoWhite', c.data.system.logoWhiteUrl);
            store.commit('setLogo', c.data.system.logoColorUrl);
           
        }).catch(function(){
            console.error(`Error while loading config...`);
        })
    }
    
    const persistSession = () =>{
        return localStorage.setItem('pl-login', JSON.stringify({
            jwt : store.state.session.jwt,
            sessionId: store.state.session.sessionId,
            permissions: store.state.session.permissions,
            refreshToken : store.state.session.refreshToken
        }));
    };

export { store, loadConfig};