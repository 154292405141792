<template>
    <div class="loader" v-if="ligado">
        <div class="row">
            <div class="col-md-6">
                <span class="title">
                    <b-spinner label="Loading..."></b-spinner> {{ titulo }}
                    <br>
                    <small style="font-size:0.55em">{{ subtitulo }}</small>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            titulo: "Loading...",
            subtitulo: "",
            ligado: false,
        }
    },
    methods: {
        start(titulo = "Loading...", subtitulo = "") {
            this.titulo = titulo;
            this.subtitulo = subtitulo;
            this.ligado = true;
        },
        stop() {
            this.ligado = false;
        }
    }
}
</script>


<style scoped>
.loader {
    z-index: 999999;
    /* background: #ffffff82; */
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    background: #000000c4;
    top: 0;
}

.title {

    font-weight: bolder !important;
    z-index: 99999;
    color: #fff !important;
    text-align: center;
    width: 100%;
    /* left: 50%; */
    FONT-SIZE: 2EM;
    top: 46%;
    position: fixed;
}

html,
body {
    margin: 0;
    height: 100%;
    overflow: hidden
}</style>